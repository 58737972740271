<template>
  <div class="home">
    <h1>j00r</h1>
    <picture>
      <img class="vaccum" src="../assets/joor.png" alt="">
    </picture>
    <h2>Zółw Domowy Sprzątajacy</h2>
    <p>Kompatybilne odkurzacze to Xiaomi Roborock v1/v2(S50/51/55). Proces podmiany dźwięków nie jest skomplikowany, lecz zawsze istnieje ryzyko, że możesz uszkodzić urządzenie. Jeśli chcesz poczytać więcej na ten temat googluj "jak spolszczyć odkurzacz xiaomi"</p>
    <p>Jeśli chcesz stworzyć swoją paczkę dźwięków, w Linkach wrzuciłem paczki z polskimi komunikatami wraz z opisem plików. Program który ci się przyda to "wav2pkg"</p>
  </div>
</template>
